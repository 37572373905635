import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import moment from "moment"

// Styles
import styles from "../styles/components/landing/articles.module.scss"

export default function Articles({ show, sidenav, title }) {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSpecialOffer {
        edges {
          node {
            title
            description
            slug
            image {
              fixed(width: 500 quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className={sidenav ? styles.sidenav : styles.grid}>
      {data.allContentfulSpecialOffer.edges
        .sort((itemA, itemB) => {
          if (sidenav) {
            return 0.5 - Math.random()
          }
          const a = moment(itemA.node.date).unix()
          const b = moment(itemB.node.date).unix()

          return a > b ? -1 : a < b ? 1 : 0
        })
        .filter(item => {
          return item.node.title !== title
        })
        .slice(0, show)
        .map((item, i) => (
          <div className={styles.item} key={i}>
            <Link to={`/offers/${item.node.slug}`}>
              <Img
                fixed={item.node.image.fixed}
                alt={item.node.title}
                objectFit="contain"
                style={{
                  height: "344px",
                  width: "100%",
                }}
              />
            </Link>

            <div className={styles.content}>
              <h1 className={styles.title}>{item.node.title}</h1>
              <p className={styles.desc}>{item.node.description}</p>
              <Link
                to={`/offers/${item.node.slug}`}
                className={styles.btnWrapper}
              >
                <button className={styles.btn}>View Offer</button>
              </Link>
            </div>
          </div>
        ))}
    </div>
  )
}
