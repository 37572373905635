import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image/withIEPolyfill"

//Components
import Header from "../components/navigation/header"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Title from "../components/smallTitle"
import Follow from "../components/follow"
import SideNav from "../components/offers"

// Styles
import styles from "../styles/templates/event.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulSpecialOffer(slug: { eq: $slug }) {
      title
      description
      image {
        file {
          url
        }
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      text {
        text
        json
      }
    }
  }
`

const Offer = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        if (!node.data.target.fields) {
          return
        }
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }
  return (
    <div>
      <Header />
      <SEO
        title={props.data.contentfulSpecialOffer.title}
        description={props.data.contentfulSpecialOffer.description}
        article={true}
        pathname={`/offers/${props.data.contentfulSpecialOffer.slug}`}
        templateImage={props.data.contentfulSpecialOffer.image.file.url}
      />
      <Layout>
        <section className={styles.blogSection}>
          <div className={styles.grid}>
            {/* Main Content */}
            <div className={styles.content}>
              <Title
                text={props.data.contentfulSpecialOffer.title}
                subtitle={props.data.contentfulSpecialOffer.description}
              />
              <div className={styles.content}>
                <div className={styles.imageGrid}>
                  <div className={styles.imageWrapper}>
                    <Img
                      fluid={props.data.contentfulSpecialOffer.image.fluid}
                      // style={{ width: "100%", minHeight: "300px" }}
                      objectFit="contain"
                      className={styles.image}
                    />
                  </div>
                </div>
                {documentToReactComponents(
                  props.data.contentfulSpecialOffer.text.json,
                  options
                )}
                <div className={styles.follow}>
                  <Follow />
                </div>
              </div>
            </div>
            <div className={styles.sideNav}>
              <SideNav
                sidenav={true}
                show={1}
                title={props.data.contentfulSpecialOffer.title}
              />
            </div>
          </div>
        </section>
      </Layout>
      <Footer />
    </div>
  )
}

export default Offer
